<template>
	<div>
		<VueApexCharts type="area" :height="height" :options="chartOptions" :series="dataSeries"></VueApexCharts>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "GraficoAreaDashboard",
	components: { VueApexCharts },
	props: [
		"dataSeries",
		"categories",
		"palette",
		"money",
		"porcentagem",
		"height",
	],
	data: () => ({
		series: [],
		chartOptions: {
			chart: {
				type: "area",
				toolbar: {
					show: false,
					tools: {
						download: false,
					},
				},
				zoom: {
					enabled: false,
				},
			},
			colors: ["#002D56", "#1764AA", "#45A5FC"],
			theme: {
				palette: "",
			},
			dataLabels: {
				enabled: true,
			},
			stroke: {
				curve: "smooth",
			},
			xaxis: {
				categories: "",
				labels: {
					show: true,
					rotate: -45,
					rotateAlways: true,
					hideOverlappingLabels: false,
					trim: false,
				},
			},
			yaxis: {
				labels: {
					show: true,
				},
			},
			tooltip: {
				x: {},
				y: {},
			},
		},
	}),
	methods: {
		init() {
			this.series = this.dataSeries;
			this.chartOptions.xaxis.categories = this.categories;
			this.chartOptions.theme.palette = this.palette;
			if (this.porcentagem) {
				this.chartOptions.tooltip.y = {
					enabled: true,
					formatter: function (val) {
						return `${parseFloat(val).toFixed(2)} %`;
					},
				};
				this.chartOptions.dataLabels = {
					enabled: true,
					formatter: function (val) {
						return `${parseFloat(val).toFixed(2)} %`;
					},
					style: {
						colors: ["#002D56", "#002D56", "#002D56"],
					},
					background: {
						enabled: true,
						borderColor: "#fff",
						opacity: 0.6,
					},
				};
			} else if (this.money) {
				this.chartOptions.tooltip.y = {
					enabled: true,
					formatter: function (val) {
						return parseFloat(val).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						});
					},
				};
				this.chartOptions.dataLabels = {
					enabled: true,
					formatter: function (val) {
						return parseFloat(val).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						});
					},
					style: {
						colors: ["#002D56", "#002D56", "#002D56"],
					},
					background: {
						enabled: true,
						borderColor: "#fff",
						opacity: 0.6,
					},
				};
			} else {
				this.chartOptions.tooltip.y = {
					enabled: true,
				};
			}
		},
	},
	created() {
		this.init();
	},
};
</script>
