<template>
	<v-card :color="color" :dark="dark" rounded :elevation="elevation">
		<v-progress-linear absolute indeterminate color="white" v-if="loading"></v-progress-linear>
		<v-card-title>
			<v-row>
				<v-col :class="classContent" :style="styleContent">
					<slot v-if="!loading"></slot>
					<v-skeleton-loader v-else class="mx-auto mt-3" width="100%" height="20" type="text"></v-skeleton-loader>
				</v-col>
			</v-row>
		</v-card-title>
		<v-divider></v-divider>
		<v-card-text>
			<v-row>
				<v-col :class="classTitle" :style="styleTitle">{{title}}</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: "CardDashboard",
	props: {
		title: {},
		color: { default: "primary" },
		dark: { default: true },
		classContent: {
			default:
				"py-1 px-8 text-center font-weight-bold text--white text-h6 text-truncate",
		},
		styleContent: { default: "color: #FFF !important;" },
		classTitle: { default: "pa-1 text-center text-truncate" },
		styleTitle: {},
		loading: {},
		elevation: {},
	},
};
</script>